@keyframes ticker {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.morse-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  height: 100vh;
  background-color: black;
}

.morse-ticker {
  display: inline-block;
  animation: ticker 30s linear infinite;
  font-size: 80rem; // Enlarged for TV screen
}

.dot {
  color: blue;
}

.dash {
  color: red;
}
